.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.App-root {
  height: 100vh;
}

.App-sub-main {
  align-items: center;
  position: absolute;
  bottom: 15px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.App-sub-child {
  background-color: rgba(8, 8, 8, 0.7);
  width: auto;
  min-width: 300px;
  max-width: 600px;
  border-radius: 45px;
  padding: 5px;
}

.App-sub {
  height: 15px;
  margin: 5px;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
